import React, { useEffect, useReducer, useState } from "react"
import PropTypes from "prop-types"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Validation from "../../helpers/Validation"
import Container from "react-bootstrap/Container"
import * as action from "../../actions/ResetPasswordActions"
import ResetPasswordReducer, {
  initialState,
} from "../../reducers/ResetPasswordReducer"
import { Spinner } from "react-bootstrap"

const NewPasswordForm = ({ actionCode }) => {
  const [state, dispatch] = useReducer(ResetPasswordReducer, initialState)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [invalidConfirmedPassword, setInvalidConfirmPassword] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(action.setActionCode(actionCode))

    action.checkValidResetActionCode(actionCode).then(dispatch)
  }, [actionCode])

  const verifyPassword = (value) => {
    if (state.password === "") {
      setInvalidPassword(false)
      return
    }

    setInvalidPassword(!Validation("password")(value))
  }

  const verifyConfirmedPassword = (value) => {
    setInvalidConfirmPassword(value !== state.password)
  }

  const onInputChange = (key) => (event) => {
    const value = event.target.value

    switch (key) {
      case "password":
        verifyPassword(value)
        dispatch(action.setPassword(value))
        break
      case "confirmPassword":
        verifyConfirmedPassword(value)
        dispatch(action.setConfirmPassword(value))
        break
      default:
        console.error(`Unsupported key ${key}`)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (invalidPassword || invalidConfirmedPassword) {
      // TODO: PRESENT ERROR
      return
    }

    if (state.completed) {
      return
    }

    setLoading(true)

    action.confirmPassword(state).then((result) => {
      dispatch(result)
      setLoading(false)
    })
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {state.errorMessage ? (
          <Form.Row className="align-items-center">
            <Form.Label style={{ color: "red" }}>
              <h3>{state.errorMessage}</h3>
            </Form.Label>
          </Form.Row>
        ) : null}
        <Form.Row className="align-items-center">
          <Col>
            <Form.Group controlId="feedbackEmailText">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={onInputChange("password")}
                isInvalid={invalidPassword}
                value={state.password}
              />
              <Form.Text className="text-muted">
                {
                  "Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji."
                }
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="feedbackEmailText">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                onChange={onInputChange("confirmPassword")}
                isInvalid={invalidConfirmedPassword}
                value={state.confirmPassword}
              />
              <Form.Text muted>{"Passwords must match"}</Form.Text>
            </Form.Group>
          </Col>
        </Form.Row>

        <Button
          variant={state.completed ? "outline-success" : "outline-light"}
          type="submit"
          disabled={isLoading || state.errorMessage}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "10px", marginBottom: "5px" }}
            />
          ) : null}

          {state.completed ? "Confirmed!" : "Set New Password"}
        </Button>
      </Form>
    </Container>
  )
}

NewPasswordForm.propTypes = {
  actionCode: PropTypes.string.isRequired,
}

export default NewPasswordForm
