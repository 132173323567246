import * as types from "../constants/ResetPasswordConstants"

export const initialState = {
  password: "",
  confirmPassword: "",
  completed: false,
  actionCode: "",
  errorMessage: null,
}

const ResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      }
    case types.SET_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload,
      }
    case types.COMPLETE:
      return {
        ...state,
        completed: action.payload,
      }
    case types.SET_ACTION_CODE:
      return {
        ...state,
        actionCode: action.payload,
      }
    case types.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default ResetPasswordReducer
