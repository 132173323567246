import React from "react"
import queryString from "query-string"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"
import ResetForm from "../components/forms/ResetForm"
import NewPasswordForm from "../components/forms/NewPasswordForm"

/**
 * REFER TO DOCUMENTATION FOR PASSWORD RESET
 * https://firebase.google.com/docs/auth/custom-email-handler
 * */

const ResetPage = ({ data, location }) => {
  const { contentfulPage } = data
  const query = queryString.parse(location.search)

  return (
    <Page
      zeroTop
      {...contentfulPage}
      seoPath="reset"
      seoTitle="Reset Account Password"
      seoKeywords={"password reset"}
      seoDescription="Forgot your Rallista account password? Reset your password here."
    >
      <FancyHeader
        title={"Forgot your password?"}
        subTitle={"Reset your Rallista account password here."}
      />
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            {query.mode === "resetPassword" ? (
              <NewPasswordForm actionCode={query.oobCode} />
            ) : (
              <ResetForm />
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

ResetPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({}),
  }),
}

const mapStateToProps = (state) => ({
  state: state.emailer,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPage)

export const pageQuery = graphql`
  query ResetPagePageQuery {
    contentfulPage(rallistaId: { eq: "feedback" }) {
      contentful_id
      seoTitle
      rallistaId
      seoDescription
    }
  }
`
