import * as types from "../constants/ResetPasswordConstants"
import Firebase from "../middleware/Firebase"

export const setActionCode = (actionCode) => {
  return {
    type: types.SET_ACTION_CODE,
    payload: actionCode,
  }
}

export const checkValidResetActionCode = async (actionCode) => {
  try {
    // Verify that it's still valid reset
    await Firebase.auth().verifyPasswordResetCode(actionCode)

    return {
      type: types.SET_ERROR,
      payload: null,
    }
  } catch (error) {
    /**
     * Review error codes here
     * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#verifypasswordresetcode
     * */
    let errorMessage = "Invalid reset link."
    switch (error.code) {
      case "auth/expired-action-code":
        errorMessage = "Reset link has expired."
        break
      case "auth/invalid-action-code":
        errorMessage = "Invalid reset link."
        break
      case "auth/user-disabled":
        errorMessage = "Reset password has been disabled."
        break
      case "auth/user-not-found":
        errorMessage = "No user found for to reset password."
        break
      default:
        errorMessage = "Unknown Error"
    }

    return {
      type: types.SET_ERROR,
      payload: errorMessage,
    }
  }
}

export const setPassword = (password) => {
  return {
    type: types.SET_PASSWORD,
    payload: password,
  }
}

export const setConfirmPassword = (password) => {
  return {
    type: types.SET_CONFIRM_PASSWORD,
    payload: password,
  }
}

export const confirmPassword = async (state) => {
  const authCode = state.actionCode
  const password = state.password

  try {
    // Verify that it's still valid reset
    const accountEmail = await Firebase.auth().verifyPasswordResetCode(authCode)

    // Reset the password for the user
    await Firebase.auth().confirmPasswordReset(authCode, password)

    // Resign in the user with the account password and email.
    await Firebase.auth().signInWithEmailAndPassword(accountEmail, password)

    return {
      type: types.COMPLETE,
      payload: true,
    }
  } catch (error) {
    /**
     * Review error codes here
     * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#verifypasswordresetcode
     * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#confirmpasswordreset
     * */
    let errorMessage = "Invalid reset link."
    switch (error.code) {
      case "auth/expired-action-code":
        errorMessage = "Reset link has expired."
        break
      case "auth/invalid-action-code":
        errorMessage = "Invalid reset link."
        break
      case "auth/user-disabled":
        errorMessage = "Reset password has been disabled."
        break
      case "auth/user-not-found":
        errorMessage = "No user found for to reset password."
        break
      case "auth/weak-password":
        errorMessage = "Password not strong enough."
        break
      default:
        errorMessage = "Unknown error"
    }

    return {
      type: types.SET_ERROR,
      payload: errorMessage,
    }
  }
}
