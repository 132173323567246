import React, { useReducer, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import Validation from "../../helpers/Validation"
import Container from "react-bootstrap/Container"
import { SendResetEmail } from "../../actions/EmailerActions"
import EmailerReducer, { initialState } from "../../reducers/EmailerReducer"

const ResetForm = () => {
  const [state, dispatch] = useReducer(EmailerReducer, initialState)

  const [invalidEmail, setInvalidEmail] = useState(false)
  const [email, setEmail] = useState("")

  const verifyEmail = (value) => {
    if (value === "") {
      setInvalidEmail(false)
      return
    }

    setInvalidEmail(!Validation("email")(value))
  }

  const onInputChange = (key) => (event) => {
    const value = event.target.value

    switch (key) {
      case "email":
        verifyEmail(value)
        setEmail(event.target.value)
        break
      default:
        console.error(`Unsupported key ${key}`)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (state.resetSent) {
      return
    }

    SendResetEmail(email).then((action) => {
      dispatch(action)
    })
  }

  let action = "Send Reset Email"

  if (state.resetSent) {
    action = (
      <>
        Reset Sent <FontAwesomeIcon icon={faCheck} />
      </>
    )
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Row className="align-items-center">
          <Col>
            <Form.Group controlId="feedbackEmailText">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="you@email.com"
                onChange={onInputChange("email")}
                isInvalid={invalidEmail}
                value={email}
              />
              <Form.Text className="text-muted">
                {"We'll send you a password reset email."}
              </Form.Text>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          variant={state.resetSent ? "outline-success" : "outline-light"}
          type="submit"
        >
          {action}
        </Button>
      </Form>
    </Container>
  )
}

export default ResetForm
